import { AxiosInstance } from 'axios';

import RESPONSE_CODES from 'constants/responseCodes';

export const setUpAuthInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('accessToken');

      if (token) {
        config.headers!.Authorization = `Bearer ${JSON.parse(token)}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response && error.response.status === RESPONSE_CODES.UNAUTHENTICATED) {
        localStorage.removeItem('accessToken');
        window.location.reload();
      }

      return Promise.reject(error);
    }
  );
};
