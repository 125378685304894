import dayjs from 'dayjs';
import { IClinicianContractDetails } from 'interfaces/ClinicianContract/IClinicianContractDetails';
import { IClinicianContractForm } from 'interfaces/ClinicianContract/IClinicianContractForm';
import { contractEngagementTypeOptions } from 'constants/contractsConstants';
import { clinicianTitleOptions } from 'constants/compensationGridsConstants';
import { contractTypeOptions } from 'constants/compensationGridsConstants';
import calculateExperience from 'helpers/calculateExperience';
import getActiveAmendment from 'helpers/ClinicianConracts/getActiveAmendment';

const loadClinicianContractFormData = (data: IClinicianContractDetails) => {

  const facilityName = data.facility.name;
  let compensation_grid_name = '';
  if (data.compensation_grid) {
    const clinicianTitleOption = clinicianTitleOptions.find(option => option.id === data.compensation_grid?.clinician_title)?.name;
    const contractTypeOption = contractTypeOptions.find(option => option.id === data.compensation_grid?.contract_type)?.name;
    const engagementOption = contractEngagementTypeOptions.find(option => option.id === data.compensation_grid?.engagement)?.name;
    compensation_grid_name = `${facilityName}, ${clinicianTitleOption}, ${contractTypeOption}, ${engagementOption}`;
  }
  const payrollDetails = getActiveAmendment(data);

  const gradDate = String(data.clinician?.grad_date);
  const startDate = String(data.clinician?.start_date);

  const formData: IClinicianContractForm = {
    clinician_id: data.clinician_id,
    facility_id: data.facility_id,
    contract_type: data.contract_type,
    compensation_rate_id: data.compensation_rate_id,
    compensation_grid_name,
    payroll_memo: data.payroll_memo || '',
    admin_memo: data.admin_memo || '',
    resign_date: data.resign_date ? dayjs(data.resign_date) : '',
    last_day_of_service: data.last_day_of_service ? dayjs(data.last_day_of_service) : '',
    contract_end_type: data.contract_end_type || '',
    status: data.status,
    start_date: startDate ? dayjs(startDate) : '',
    grad_date: gradDate ? dayjs(gradDate) : '',
    experience: gradDate ? calculateExperience(gradDate) : '',
    payroll_details: {
      engagement: payrollDetails.engagement,
      fte: payrollDetails.fte || '',
      contract_term: payrollDetails.contract_term || '',
      staff_category: payrollDetails.staff_category || '',
      contracted_hours: payrollDetails.contracted_hours || '',
      weeks_of_service: payrollDetails.weeks_of_service || '',
      paid_time_off: payrollDetails.paid_time_off || '',
      annual_rate: payrollDetails.annual_rate || '',
      hourly_rate: payrollDetails.hourly_rate || '',
      notes: payrollDetails.notes || '',
      contracted_frequency: payrollDetails.contracted_frequency || '',
      payroll_frequency: payrollDetails.payroll_frequency || '',
      effective_date: dayjs(payrollDetails.effective_date),
      execution_date: payrollDetails.execution_date ? dayjs(payrollDetails.execution_date) : '',
      malpractice_deduction: payrollDetails.malpractice_deduction || '',
    },
  };

  return formData;
};

export default loadClinicianContractFormData;
