import * as Yup from 'yup';
import { payrollIdMaxLength, stringMaxLength, addressMaxLength, shortStringMaxLength,
  phoneRegex, bankAccountNumberMaxLength, ssnRegex, accountRoutingNumberRegex, emailRegex } from 'constants/fieldsLimits';
import VALIDATION_MESSAGES from 'constants/validationMessages';

export const clinicianValidationSchema = Yup.object({
  payroll_id: Yup.string()
    .nullable()
    .optional()
    .max(payrollIdMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(payrollIdMaxLength)),
  first_name: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  last_name: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  maiden_name: Yup.string()
    .nullable()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  entity_name: Yup.string()
    .nullable()
    .optional()
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  ssn: Yup.string()
    .nullable()
    .optional()
    .test(
      'is valid',
      VALIDATION_MESSAGES.SSN_EXACT_LENGTH,
      (value) => ( value ? ssnRegex.test(String(value)) : true)
    ),
  npi: Yup.string()
    .nullable()
    .optional()
    .test(
      'is valid',
      VALIDATION_MESSAGES.PHONE_EXACT_LENGTH,
      (value) => ( value ? /^[0-9]{10}$/.test(String(value)) : true)
    ),
  street: Yup.string()
    .nullable()
    .optional()
    .max(addressMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(addressMaxLength)),
  apt: Yup.string()
    .nullable()
    .optional()
    .max(addressMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(addressMaxLength)),
  city: Yup.string()
    .nullable()
    .optional()
    .max(addressMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(addressMaxLength)),
  zip: Yup.string()
    .nullable()
    .optional()
    .max(shortStringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(shortStringMaxLength)),
  office: Yup.string()
    .nullable()
    .optional()
    .test(
      'is valid',
      VALIDATION_MESSAGES.PHONE_EXACT_LENGTH,
      (value) => ( value ? phoneRegex.test(String(value)) : true)
    ),
  work_email: Yup.string()
    .nullable()
    .optional()
    .test('is valid', VALIDATION_MESSAGES.EMAIL, (value => ( value ? emailRegex.test(value) : true )))
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  cell: Yup.string()
    .nullable()
    .optional()
    .test(
      'is valid',
      VALIDATION_MESSAGES.PHONE_EXACT_LENGTH,
      (value) => ( value ? phoneRegex.test(String(value)) : true)
    ),
  personal_email: Yup.string()
    .nullable()
    .optional()
    .test('is valid', VALIDATION_MESSAGES.EMAIL, (value => ( value ? emailRegex.test(value) : true )))
    .max(stringMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(stringMaxLength)),
  bank_account_1: Yup.string()
    .nullable()
    .optional()
    .max(bankAccountNumberMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(bankAccountNumberMaxLength)),
  routing_number_1: Yup.string()
    .nullable()
    .optional()
    .test(
      'is valid',
      VALIDATION_MESSAGES.SSN_EXACT_LENGTH,
      (value) => ( value ? accountRoutingNumberRegex.test(String(value)) : true)
    ),
  bank_account_2: Yup.string()
    .nullable()
    .optional()
    .max(bankAccountNumberMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(bankAccountNumberMaxLength)),
  routing_number_2: Yup.string()
    .nullable()
    .optional()
    .test(
      'is valid',
      VALIDATION_MESSAGES.SSN_EXACT_LENGTH,
      (value) => ( value ? accountRoutingNumberRegex.test(String(value)) : true)
    ),
  bank_account_3: Yup.string()
    .nullable()
    .optional()
    .max(bankAccountNumberMaxLength, VALIDATION_MESSAGES.STRING_MAX_LENGTH(bankAccountNumberMaxLength)),
  routing_number_3: Yup.string()
    .nullable()
    .optional()
    .test(
      'is valid',
      VALIDATION_MESSAGES.SSN_EXACT_LENGTH,
      (value) => ( value ? accountRoutingNumberRegex.test(String(value)) : true)
    ),
  date_of_birth: Yup.date()
    .nullable()
    .optional()
    .typeError('Invalid Date')
    .max(new Date(), VALIDATION_MESSAGES.DATE_MUST_BE_PRESENT),
  grad_date: Yup.date()
    .nullable()
    .optional()
    .typeError('Invalid Date')
    .max(new Date(), VALIDATION_MESSAGES.DATE_MUST_BE_PRESENT),
  start_date: Yup.date()
    .nullable()
    .optional()
    .typeError('Invalid Date'),
});
