import { IPublicClientApplication } from '@azure/msal-browser';
import { request } from 'api/instance';
import { AxiosError } from 'axios';
import { ICompensationGridDetails } from 'interfaces/CompensationGrid/ICompensationGridDetails';
import { IFilterParams } from 'interfaces/IFilterParams';
import { ContractType } from 'types';

export const CompensationGridAPI = {

  createCompensationGrid: async (formData: ICompensationGridDetails) => {
    try {
      const response = await request({
        url: 'v1/compensation_grids',
        method: 'POST',
        data: {
          data: {
            compensation_grid: formData,
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },

  updateCompensationGrid: async (id: number, formData: ICompensationGridDetails) => {
    try {
      const response = await request({
        url: `v1/compensation_grids/${id}`,
        method: 'PUT',
        data: {
          data: {
            compensation_grid: formData,
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },

  getAll: async (params: IFilterParams, _: IPublicClientApplication | undefined, signal: AbortSignal | undefined) => {
    try {
      const response = await request({
        url: 'v1/compensation_grids',
        method: 'GET',
        params,
      }, { signal });
      return response.data;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  },

  getDetails: async (compensationGridId: number) => {
    const response = await request({
      url: `v1/compensation_grids/${compensationGridId}`,
      method: 'GET',
    });

    return response.data.data;
  },

  findGrid: async (formData: { facility_id: number, clinician_title: string, contract_type: ContractType, engagement: string }) => {
    const response = await request({
      url: 'v1/compensation_grids/find',
      method: 'GET',
      params: (({ facility_id, clinician_title, contract_type, engagement }) =>
        ({ facility_id, clinician_title, contract_type, engagement }))(formData),
    });

    return response.data.data;
  },

  getContractedHours: async (type: string) => {
    const response = await request({
      url: 'v1/compensation_grids/contracted_hours',
      method: 'GET',
      params: {
        type,
      },
    });
    return response.data.data;
  },
};
