import { styled } from '@mui/material/styles';
import { colors } from 'styles/globalStyles';

export const PageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100dvh - 180px)',
  marginTop: '16px',
  background: colors.white,
  padding: '20px',
});
