import { FormEvent, useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';

import { PermissionsAPI } from 'api/PermissionsAPI';
import { ClinicianAPI } from 'api/ClinicianAPI';

import { OutlinedButton, SubmitButton, Input, Select, DatePicker, PasswordInput } from 'components/UIComponents';
import { SectionContainer, ActionsBar, InputFieldsWrapper } from 'components/base';

import { IClinician } from 'interfaces/Clinician/IClinician';

import { clinicianTitleOptions, stateOptions } from 'constants/dropdownOptions';
import { useToast } from 'context/ToastContext';
import { useFieldsPermissions } from 'context/FieldsPermissionsContext';
import { clinicianValidationSchema } from 'helpers/validation/clinicianValidationSchema';
import handleFormSubmitResponse from 'helpers/handleFormSubmitResponse';
import filterObjectKeys from 'helpers/filterObjectKeys';
import isSectionRendering from 'helpers/isSectionRendering';
import calculateExperience from 'helpers/calculateExperience';
import formatAccountRoutingNumber from 'helpers/formatAccountRoutingNumber';
import formatPhone from 'helpers/formatPhone';
import formatSSN from 'helpers/formatSSN';
import useData from 'hooks/useData';

import { FieldsSeparator } from './styledComponents';

const ClinicianForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { clinicianId } = useParams();
  const { onToastOpen } = useToast();
  const { setFieldsPermissions } = useFieldsPermissions();
  const fieldsPermissionsData = useData(PermissionsAPI.getAllowedAttributes, 'Clinician');

  useEffect(() => {
    if (fieldsPermissionsData) {
      setFieldsPermissions({...fieldsPermissionsData, read_allowed: [...fieldsPermissionsData.read_allowed, 'experience']});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsPermissionsData]);

  const [clinicianFormInformation, setClinicianFormInformation] = useState<IClinician>(filterObjectKeys({
    payroll_id: '',
    start_date: '',
    first_name: '',
    last_name: '',
    maiden_name: '',
    title: '',
    entity_name: '',
    ssn: '',
    npi: '',
    date_of_birth: '',
    birthday: '',
    grad_date: '',
    experience: '',
    street: '',
    apt: '',
    city: '',
    state: '',
    zip: '',
    office: '',
    work_email: '',
    cell: '',
    personal_email: '',
    bank_account_1: '',
    routing_number_1: '',
    bank_account_2: '',
    routing_number_2: '',
    bank_account_3: '',
    routing_number_3: '',
  }, fieldsPermissionsData?.read_allowed) as IClinician);

  useEffect(() => {
    if (clinicianId && fieldsPermissionsData) {
      ClinicianAPI.getDetails(Number(clinicianId)).then(async (data: IClinician) => {
        let formData: IClinician = {
          ...data,
          office: formatPhone(data.office || ''),
          cell: formatPhone(data.cell || ''),
          routing_number_1: formatAccountRoutingNumber(data.routing_number_1 || ''),
          routing_number_2: formatAccountRoutingNumber(data.routing_number_2 || ''),
          routing_number_3: formatAccountRoutingNumber(data.routing_number_3 || ''),
          start_date: data.start_date ? dayjs(data.start_date) : '',
          date_of_birth: data.date_of_birth ? dayjs(data.date_of_birth) : '',
          grad_date: data.grad_date ? dayjs(data.grad_date) : '',
          experience: calculateExperience(data.grad_date as string),
        };
        if (fieldsPermissionsData.read_allowed.includes('ssn')) {
          const { ssn } = await ClinicianAPI.getSSN(Number(clinicianId));
          formData.ssn = formatSSN(ssn || '');
        };
        setClinicianFormInformation(formData);
      });
    };
  }, [clinicianId, fieldsPermissionsData]);

  const populateEmail = (firstName: string, lastName: string, setFieldValue: any, setFieldTouched: any) => {
    if (firstName && lastName) {
      setFieldValue('work_email', `${firstName.substring(0, 1).toLowerCase()}${lastName.toLowerCase()}@somniainc.com`);
      setTimeout(() => setFieldTouched('work_email', true));
    }
  };

  if (!fieldsPermissionsData) return null;

  return(
    <Formik
      initialValues={clinicianFormInformation}
      enableReinitialize={true}
      validationSchema={clinicianValidationSchema}
      validateOnBlur
      onSubmit={async (values, { setFieldError }) => {
        let response;
        if (clinicianId) {
          response = await ClinicianAPI.updateClinician(Number(clinicianId), values, fieldsPermissionsData.update_allowed);
        } else {
          response = await ClinicianAPI.createClinician(values, fieldsPermissionsData.update_allowed);
        }
        handleFormSubmitResponse(response, setFieldError, onToastOpen, navigate, searchParams.get('redirectTo') as string);
      }}
    >
      {({ errors, handleSubmit, dirty, setFieldValue, values, setFieldTouched }) => (
        <Form>
          {isSectionRendering(fieldsPermissionsData.read_allowed, ['payroll_id', 'start_date', 'first_name', 'last_name', 'maiden_name',
            'title', 'entity_name', 'ssn', 'npi', 'date_of_birth', 'birthday', 'grad_date', 'experience']) &&
            <SectionContainer title='Clinician Info'>
              {isSectionRendering(fieldsPermissionsData.read_allowed, ['payroll_id', 'start_date']) &&
                <InputFieldsWrapper>
                  <Input name='payroll_id' label='Payroll ID'/>
                  <DatePicker name='start_date' label='Start Date' disableFuture={false} />
                </InputFieldsWrapper>
              }
              {isSectionRendering(fieldsPermissionsData.read_allowed, ['first_name', 'last_name', 'maiden_name']) &&
                <InputFieldsWrapper>
                  <Input
                    name='first_name'
                    label='First Name'
                    onBlur={(trimmedValue) => populateEmail(trimmedValue, values.last_name, setFieldValue, setFieldTouched)}
                    required
                  />
                  <Input
                    name='last_name'
                    label='Last Name'
                    onBlur={(trimmedValue) => populateEmail(values.first_name, trimmedValue, setFieldValue, setFieldTouched)}
                    required
                  />
                  <Input name='maiden_name' label='Maiden Name'/>
                </InputFieldsWrapper>
              }
              {isSectionRendering(fieldsPermissionsData.read_allowed, ['title', 'entity_name', 'ssn', 'npi']) &&
                <InputFieldsWrapper>
                  <Select name='title' label='Title' options={clinicianTitleOptions} width='narrow'/>
                  <Input name='entity_name' label='Entity Name' width='wide'/>
                  <PasswordInput name='ssn' label='SSN' width='narrow' type='ssn'/>
                  <Input name='npi' label='NPI' width='narrow' type='npi'/>
                </InputFieldsWrapper>
              }
              {isSectionRendering(fieldsPermissionsData.read_allowed, ['date_of_birth', 'birthday', 'grad_date', 'experience']) &&
                <InputFieldsWrapper>
                  <DatePicker
                    name='date_of_birth'
                    label='Date of Birth'
                    width='narrow'
                    onChange={(value: any) => setFieldValue('birthday', value?.isValid() ? value.format('MMMM D') : '')}
                  />
                  <Input name='birthday' label='Birthday' width='narrow' disabled/>
                  <DatePicker
                    name='grad_date'
                    label='Grad Date'
                    width='narrow'
                    onChange={(value: any) => {
                      if (value?.isValid()) {
                        setFieldValue('experience', calculateExperience(value));
                      } else {
                        setFieldValue('experience', '');
                      }
                    }
                    }
                  />
                  <Input name='experience' label='Experience' width='narrow' disabled/>
                </InputFieldsWrapper>
              }
            </SectionContainer>
          }
          {isSectionRendering(fieldsPermissionsData.read_allowed, ['street', 'apt', 'city', 'state', 'zip', 'office', 'work_email', 'cell',
            'personal_email']) &&
            <SectionContainer title='Contact Details'>
              {isSectionRendering(fieldsPermissionsData.read_allowed, ['street', 'apt']) &&
                <InputFieldsWrapper>
                  <Input name='street' label='Street'/>
                  <Input name='apt' label='Apt, Suite, Floor'/>
                </InputFieldsWrapper>
              }
              {isSectionRendering(fieldsPermissionsData.read_allowed, ['city', 'state', 'zip']) &&
                <InputFieldsWrapper>
                  <Input name='city' label='City'/>
                  <Select name='state' label='State' options={stateOptions} width='narrow'/>
                  <Input name='zip' label='ZIP Code' width='narrow'/>
                </InputFieldsWrapper>
              }
              {isSectionRendering(fieldsPermissionsData.read_allowed, ['office', 'work_email']) &&
                <InputFieldsWrapper>
                  <Input name='office' label='Office' type='phone'/>
                  <Input name='work_email' label='Work Email'/>
                </InputFieldsWrapper>
              }
              {isSectionRendering(fieldsPermissionsData.read_allowed, ['cell', 'personal_email']) &&
                <InputFieldsWrapper>
                  <Input name='cell' label='Cell' type='phone'/>
                  <Input name='personal_email' label='Personal Email'/>
                </InputFieldsWrapper>
              }
            </SectionContainer>
          }
          {isSectionRendering(fieldsPermissionsData.read_allowed, ['bank_account_1', 'routing_number_1',
            'bank_account_2', 'routing_number_2', 'bank_account_3', 'routing_number_3']) &&
            <SectionContainer title='Bank Accounts'>
              {isSectionRendering(fieldsPermissionsData.read_allowed, ['bank_account_1', 'routing_number_1']) &&
                <InputFieldsWrapper styles={{gap: '2px'}}>
                  <Input name='bank_account_1' label='Account' type='iban'/>
                  <FieldsSeparator />
                  <Input name='routing_number_1' label='Routing Number' type='accountRoutingNumber'/>
                </InputFieldsWrapper>
              }
              {isSectionRendering(fieldsPermissionsData.read_allowed, ['bank_account_2', 'routing_number_2']) &&
                <InputFieldsWrapper styles={{gap: '2px'}}>
                  <Input name='bank_account_2' label='Account' type='iban'/>
                  <FieldsSeparator />
                  <Input name='routing_number_2' label='Routing Number' type='accountRoutingNumber'/>
                </InputFieldsWrapper>
              }
              {isSectionRendering(fieldsPermissionsData.read_allowed, ['bank_account_3', 'routing_number_3']) &&
                <InputFieldsWrapper styles={{gap: '2px'}}>
                  <Input name='bank_account_3' label='Account' type='iban'/>
                  <FieldsSeparator />
                  <Input name='routing_number_3' label='Routing Number' type='accountRoutingNumber'/>
                </InputFieldsWrapper>
              }
            </SectionContainer>
          }
          <ActionsBar>
            <OutlinedButton
              onClick={() => navigate(-1)}
            >
              Cancel
            </OutlinedButton>
            <SubmitButton
              disabled={!dirty || !!Object.values(errors).length}
              onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}
            >
              Save Clinician
            </SubmitButton>
          </ActionsBar>
        </Form>
      )}
    </Formik>
  );
};

export default ClinicianForm;
