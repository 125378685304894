import { useState, useEffect } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import useInfiniteScroll from 'hooks/useInfiniteScroll';

import { ITableColumn } from 'interfaces/ITableColumn';
import { IFilterParams } from 'interfaces/IFilterParams';
import { PageHeader, TableWithLazyLoad } from 'components/base';
import { SearchField } from 'components/UIComponents';
import { IPublicClientApplication } from '@azure/msal-browser';

import { PageWrapper } from './styledComponents';

interface Props {
  tableProps: {
    name: string;
    columns: ITableColumn[];
    editAction?: boolean
    rowsAsLinks?: boolean;
    withActions?: boolean;
  };
  pageTitle: string;
  dataFormatter: ((item: any) => object) | ((item: any, index: number) => object);
  dataProvider: (
    params: IFilterParams, msalInstance?: IPublicClientApplication, signal?: AbortSignal
  ) => Promise<AxiosResponse | AxiosError>;
  barActions?: React.ReactNode;
  msalInstance?: IPublicClientApplication;
  searchProps: {
    allowSearch: true,
    search: string,
    setSearch: Function,
  } | {
    allowSearch: false,
  },
}

const perPage = 100;
const defaultPage = 1;

const ListOfRecords = ({
  tableProps,
  pageTitle,
  dataFormatter,
  dataProvider,
  barActions,
  msalInstance,
  searchProps,
}: Props) => {

  const loadMore = () => {
    if (hasMore) {
      getData({ newPage: page + 1 });
    }
  };

  const [ref] = useInfiniteScroll(loadMore);
  const [rowData, setRowData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const doSearch = (searchValue: string, searchCallback: () => void, abortController: AbortController): void => {
    searchProps.allowSearch && searchProps.setSearch(searchValue);
    setRowData([]);
    setHasMore(true);
    setPage(defaultPage);
    getData({ searchValue, newPage: 1, signal: abortController.signal }).then(() => {
      searchCallback();
    });
  };

  const getData = ({ searchValue, newPage, signal }: { searchValue?: string; newPage?: number, signal?: AbortSignal }): Promise<void> => {
    setLoading(true);
    const search = searchProps.allowSearch ? searchProps.search : '';
    const result = dataProvider({
      search: searchValue == null ? search.trim() : searchValue.trim(),
      per_page: perPage,
      page: newPage || page,
    }, msalInstance, signal).then((data: AxiosResponse | AxiosError) => {
      if (!axios.isAxiosError(data)) {
        const items = data.data.objects;
        setHasMore(items.length === perPage);
        setRowData((prevState) => prevState.concat(items));
        setLoading(false);
        if (newPage) {
          setPage(newPage);
        }
      }
    });
    return result;
  };

  useEffect(() => {
    setRowData([]);
    getData({ newPage: defaultPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider]);

  return (
    <>
      <PageHeader pageTitle={pageTitle} isSticky={false}>
        <>
          { searchProps.allowSearch && <SearchField onChange={doSearch}/> }
          {barActions}
        </>
      </PageHeader>
      <PageWrapper>
        <TableWithLazyLoad
          {...tableProps}
          data={rowData.map(dataFormatter)}
          showSkeleton={loading}
          tableRef={ref}
          search={searchProps.allowSearch ? searchProps.search : ''}
        />
      </PageWrapper>
    </>
  );
};

export default ListOfRecords;
