import { styled } from '@mui/material/styles';

export const StyledMemoContainer = styled('div')({
  display: 'grid',
  gap: '10px',
  '&>p': {
    margin: 0,
  },
  '& p': {
    margin: 0,
    marginBottom: '5px',
  },
  '&>li': {
    margin: '-5px 0',
  },
});
