import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { colors } from 'styles/globalStyles';

import { IClinician } from 'interfaces/Clinician/IClinician';

import { PageContainer, PageHeader, SectionContainer, DetailsContainer, DetailsWrapper, ActionsBar } from 'components/base';
import { TextButton, CustomIcon, OutlinedButton, IconButton } from 'components/UIComponents';

import { useFieldsPermissions } from 'context/FieldsPermissionsContext';

import isSectionRendering from 'helpers/isSectionRendering';
import formatPhone from 'helpers/formatPhone';
import formatAccountRoutingNumber from 'helpers/formatAccountRoutingNumber';
import calculateExperience from 'helpers/calculateExperience';
import formatDate from 'helpers/formatDate';
import formatIBAN from 'helpers/formatIBAN';
import formatSSN from 'helpers/formatSSN';
import useData from 'hooks/useData';
import useAuth from 'hooks/useAuth';

import { ClinicianAPI } from 'api/ClinicianAPI';
import { PermissionsAPI } from 'api/PermissionsAPI';

import { SsnContainer } from './styledComponents';

const ClinicianDetails = () => {
  const { user } = useAuth();
  const editAllowed = user?.allowed_write_resources.includes('clinicians');
  const { clinicianId } = useParams();
  const { setFieldsPermissions } = useFieldsPermissions();
  const [ssnVisible, setSsnVisible] = useState(false);
  const [ssn, setSsn] = useState('');

  useEffect(() => {
    if(ssnVisible) {
      ClinicianAPI
        .getSSN(Number(clinicianId))
        .then((data) => {
          setSsn(data.ssn);
        });
    }
  }, [ssnVisible, clinicianId]);

  const toggleSsnVisibility = () => {
    setSsnVisible(!ssnVisible);
  };

  const fieldsPermissionsData = useData(PermissionsAPI.getAllowedAttributes, 'Clinician');
  const clinician = useData(ClinicianAPI.getDetails, Number(clinicianId)) as IClinician;

  useEffect(() => {
    if (fieldsPermissionsData) {
      setFieldsPermissions(fieldsPermissionsData);
    }
  }, [setFieldsPermissions, fieldsPermissionsData]);

  if (!(clinician && fieldsPermissionsData)) return null;

  return(
    <PageContainer style={{marginBottom: '66px'}}>
      <PageHeader pageTitle={`${clinician.last_name} ${clinician.first_name} ${clinician.maiden_name ? `(${clinician.maiden_name})` :''}`}>
        {editAllowed &&
          <TextButton color='blue' href={`${clinicianId}/edit?redirectTo=/clinicians/${clinicianId}`}>
            <CustomIcon size='normal' name='edit'/>
            Edit
          </TextButton>
        }
      </PageHeader>
      {isSectionRendering(fieldsPermissionsData.read_allowed, ['payroll_id', 'start_date', 'first_name', 'last_name', 'maiden_name',
        'title', 'entity_name', 'ssn', 'npi', 'date_of_birth', 'birthday', 'grad_date']) &&
        <SectionContainer title='Clinician Info'>
          <DetailsWrapper>
            <DetailsContainer name='payroll_id' label='Payroll ID'>{clinician.payroll_id}</DetailsContainer>
            <DetailsContainer name='start_date' label='Start Date'>
              {formatDate(clinician.start_date as string || '')}
            </DetailsContainer>
            <DetailsContainer name='first_name' label='First Name'>{clinician.first_name}</DetailsContainer>
            <DetailsContainer name='last_name' label='Last Name'>{clinician.last_name}</DetailsContainer>
            <DetailsContainer name='maiden_name' label='Maiden Name'>{clinician.maiden_name}</DetailsContainer>
            <DetailsContainer name='title' label='Title'>{clinician.title}</DetailsContainer>
            <DetailsContainer name='entity_name' label='Entity Name'>{clinician.entity_name}</DetailsContainer>
            <DetailsContainer name='ssn' label='SSN'>
              {ssn !== null ?
                <SsnContainer>
                  { ssnVisible ? formatSSN(ssn || '') : '***-**-****' }
                </SsnContainer>
                :
                <SsnContainer>no SSN</SsnContainer>
              }
              {ssn !== null &&
                <IconButton onClick={toggleSsnVisibility} styles={{root: { padding: '0' }, hover: { backgroundColor: 'inherit' }}}>
                  <CustomIcon
                    size='x-normal'
                    name={ssnVisible ? 'eye' : 'crossedEye'}
                    color={colors.primaryBlue}
                    hoverColor={colors.hoveredBlue}
                  />
                </IconButton>
              }
            </DetailsContainer>
            <DetailsContainer name='npi' label='NPI'>{clinician.npi}</DetailsContainer>
            <DetailsContainer name='date_of_birth' label='Date of Birth'>
              {formatDate(clinician.date_of_birth as string || '')}
            </DetailsContainer>
            <DetailsContainer name='birthday' label='Birthday'>{clinician.birthday}</DetailsContainer>
            <DetailsContainer name='grad_date' label='Grad Date'>{formatDate(clinician.grad_date as string || '')}</DetailsContainer>
            <DetailsContainer name='grad_date' label='Experience'>
              {clinician.grad_date ? calculateExperience(clinician.grad_date as string) : ''}
            </DetailsContainer>
          </DetailsWrapper>
        </SectionContainer>
      }

      {isSectionRendering(fieldsPermissionsData.read_allowed, ['address', 'office', 'work_email', 'cell', 'personal_email']) &&
        <SectionContainer title='Contact Details'>
          <DetailsWrapper>
            <DetailsContainer name='address' label='Address'>{clinician.address}</DetailsContainer>
            <DetailsContainer name='office' label='Office'>{formatPhone(clinician.office || '')}</DetailsContainer>
            <DetailsContainer name='work_email' label='Work Email'>{clinician.work_email}</DetailsContainer>
            <DetailsContainer name='cell' label='Cell'>{formatPhone(clinician.cell || '')}</DetailsContainer>
            <DetailsContainer name='personal_email' label='Personal Email'>{clinician.personal_email}</DetailsContainer>
          </DetailsWrapper>
        </SectionContainer>
      }
      {isSectionRendering(fieldsPermissionsData.read_allowed, ['bank_account_1', 'routing_number_1', 'bank_account_2', 'routing_number_2',
        'bank_account_3', 'routing_number_3']) &&
        <SectionContainer title='Bank Accounts'>
          {isSectionRendering(fieldsPermissionsData.read_allowed, ['bank_account_1', 'routing_number_1']) &&
            <DetailsWrapper>
              <DetailsContainer name='bank_account_1' label='Account'>{formatIBAN(clinician.bank_account_1 || '')}</DetailsContainer>
              <DetailsContainer name='routing_number_1' label='Routing Number'>
                {formatAccountRoutingNumber(clinician.routing_number_1 || '')}
              </DetailsContainer>
            </DetailsWrapper>
          }
          {isSectionRendering(fieldsPermissionsData.read_allowed, ['bank_account_2', 'routing_number_2']) &&
            <DetailsWrapper>
              <DetailsContainer name='bank_account_2' label='Account'>{formatIBAN(clinician.bank_account_2 || '')}</DetailsContainer>
              <DetailsContainer name='routing_number_2' label='Routing Number'>
                {formatAccountRoutingNumber(clinician.routing_number_2 || '')}
              </DetailsContainer>
            </DetailsWrapper>
          }
          {isSectionRendering(fieldsPermissionsData.read_allowed, ['bank_account_3', 'routing_number_3']) &&
            <DetailsWrapper>
              <DetailsContainer name='bank_account_3' label='Account'>{formatIBAN(clinician.bank_account_3 || '')}</DetailsContainer>
              <DetailsContainer name='routing_number_3' label='Routing Number'>
                {formatAccountRoutingNumber(clinician.routing_number_3 || '')}
              </DetailsContainer>
            </DetailsWrapper>
          }
        </SectionContainer>
      }
      <ActionsBar>
        <OutlinedButton href='/clinicians'>
          Back
        </OutlinedButton>
      </ActionsBar>
    </PageContainer>
  );
};

export default ClinicianDetails;
