export const fontFamily = "'Open Sans', sans-serif";

export const colors = {
  primaryBlue: '#004DA1',
  hoveredBlue: '#004188',
  hoveredOutlinedBlue: '#E1EAFA',
  pressedBlue: '#003875',
  disabledBlue: '#667EB1',
  hoveredBackgroundBlue: '#F7FAFF',
  highlightedBlue: '#EDF7FF',
  focusHoverBlue: '#E9F3FE',

  secondaryGreen: '#006A4A',
  lightGreen: '#BFE1CB',

  black: '#000000',
  white: '#FFFFFF',

  red: '#E82121',
  hoveredBackgroundRed: '#FFF8F8',
  hoveredTextRed: '#E73800',

  disabledGray: '#BDBDBD',
  borderGray: '#CFD4D9',
  disabledBorderGray: '#BBBFC3',
  inputDisabledGray: '#F6F6F6',
  textGray: '#6C757D',
  backgroundGray: '#F8F8FB',
  tableHeaderGray: '#F5F5F5',
  tableBorderGray: '#E8E8E8',
  hoverGray: '#F8F8F8',
  lightGrey: '#ECEDEF',
  filterGray: '#C4C4C4',

  successAlertBorderGreen: '#8DBD89',
  successAlertGreen: '#EDFFED',
  successAlertTextGreen: '#1D4620',

  infoAlertBorderBlue: '#7E92B9',
  infoAlertBlue: '#E5F6FD',
  infoAlertTextBlue: '#014361',

  errorAlertBorderRed: '#D15D5BC7',
  errorAlertRed: '#FDEDED',
  errorAlertTextRed: '#A70704',

  warningAlertBorderYellow: '#EBB86E',
  warningAlertYellow: '#FFF4E5',
  warningAlertTextYellow: '#BB6E00',
  statusYellow: '#FFE8BA',
  textYellow: '#B38100',
};

export const fieldsWidthValues = {
  'full-width': '100%',
  'ultra-wide': '590px',
  'wide': '439px',
  'normal': '288px',
  'extra-narrow': '187px',
  'narrow': '137px',
};
