export const engagementTypeOptions = [
  { id: 'full_time', name: 'Full-Time'},
  { id: 'per_diem', name: 'Per-Diem'},
];

export const clinicianTitleOptions = [
  { id: 'md_do', name: 'MD/DO' },
  { id: 'crna', name: 'CRNA' },
  { id: 'rn', name: 'RN' },
  { id: 'np', name: 'NP' },
  { id: 'admin', name: 'Admin' },
];

export const contractTypeOptions = [
  { id: 'w2', name: 'W2' },
  { id: 'pc', name: 'PC' },
];

export const staffCategoryOptions = [
  { id: 'General', name: 'General' },
  { id: 'Cardiac', name: 'Cardiac' },
  { id: 'General - Call Taking', name: 'General - Call Taking' },
  { id: 'General - Non Call Taking', name: 'General - Non Call Taking' },
  { id: 'General - 2 Weeks on, 1 Week off', name: 'General - 2 Weeks on, 1 Week off' },
  { id: 'General - 39 Weeks on', name: 'General - 39 Weeks on' },
  { id: 'General - 26 Weeks on', name: 'General - 26 Weeks on' },
  { id: 'Medical Director', name: 'Medical Director' },
  { id: 'OB Service', name: 'OB Service' },
  { id: 'Trauma', name: 'Trauma' },
  { id: 'Admin', name: 'Admin' },
  { id: 'ARRP - Cohort 1', name: 'ARRP - Cohort 1' },
  { id: 'ARRP - Cohort 2', name: 'ARRP - Cohort 2' },
  { id: 'ARRP - Cohort 3', name: 'ARRP - Cohort 3' },
  { id: 'ARRP - Cohort 4', name: 'ARRP - Cohort 4' },
];

export const rateTypeOptions = [
  { id: 'flat', name: 'Flat' },
  { id: 'tiered', name: 'Tiered' },
];

export const stepIncreaseOptions = [
  { id: 'Effective the 1st day of the quarter following anniversary', name: 'Effective the 1st day of the quarter following anniversary' },
];

export const compensationTerms = [
  { fieldName: 'time_off', title: 'Time-Off, weeks', showOn: 'full_time', changing_default_value: true },
  { fieldName: 'time_off_cme', title: 'Additional Time-Off CME, days', showOn: 'full_time', changing_default_value: true },
  { fieldName: 'overtime', title: 'Overtime', showOn: 'full_time', changing_default_value: false },
  { fieldName: 'malpractice', title: 'Malpractice Responsibility', showOn: 'both', changing_default_value: false },
  { fieldName: 'limit_per_event', title: 'Malpractice Limit Per Event', showOn: 'both', changing_default_value: false },
  { fieldName: 'limit_yearly', title: 'Yearly Aggregated Malpractice Limit', showOn: 'both', changing_default_value: false },
  { fieldName: 'health_benefits', title: 'Health Benefits', showOn: 'full_time', changing_default_value: true },
  { fieldName: 'dental_benefits', title: 'Dental Benefits', showOn: 'full_time', changing_default_value: true },
  { fieldName: 'vision_benefits', title: 'Vision Benefits', showOn: 'full_time', changing_default_value: true },
  { fieldName: 'li_benefits', title: 'Life Insurance Benefits', showOn: 'full_time', changing_default_value: true },
  { fieldName: 'ltd_benefits', title: 'Long Term Disability Benefits', showOn: 'full_time', changing_default_value: true },
  { fieldName: 'std_benefits', title: 'Short Term Disability Benefits', showOn: 'full_time', changing_default_value: true },
  { fieldName: 'voluntary_benefits', title: 'Voluntary Benefits', showOn: 'full_time', changing_default_value: true },
  { fieldName: 'match', title: '401(k) Match', showOn: 'full_time', changing_default_value: false },
  { fieldName: 'state_licenses', title: 'State Licenses, AANA Dues & Certification Fees', showOn: 'full_time',
    changing_default_value: false },
  { fieldName: 'cme_pdme', title: 'CME / PDME', showOn: 'full_time', changing_default_value: true },
  { fieldName: 'workers_comp', title: 'Workers Comp', showOn: 'both', changing_default_value: false },
  { fieldName: 'termination_notice', title: 'Termination Notice', showOn: 'both', changing_default_value: false },
  { fieldName: 'miscellaneous', title: 'Miscellaneous', showOn: 'per_diem', changing_default_value: false },
];

export const paidInFullOptions = [
  {id: 'true', name: 'Yes'},
  {id: 'false', name: 'No'},
];

export const payMethodOptions = [
  { id: 'Payroll', name: 'Payroll' },
  { id: 'AP', name: 'AP' },
];
