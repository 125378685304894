import { AxiosError } from 'axios';
import { IPublicClientApplication } from '@azure/msal-browser';
import { request } from '../instance';
import filterObjectKeys from 'helpers/filterObjectKeys';
import { IClinician } from 'interfaces/Clinician/IClinician';
import { IFilterParams } from 'interfaces/IFilterParams';
import dayjs from 'dayjs';

const convertedFields = (formData: IClinician) => {
  return {
    cell: formData.cell?.replace(/-/g, ''),
    office: formData.office?.replace(/-/g, ''),
    ssn: formData.ssn?.replace(/-/g, ''),
    routing_number_1: formData.routing_number_1?.replace(/ /g, ''),
    routing_number_2: formData.routing_number_2?.replace(/ /g, ''),
    routing_number_3: formData.routing_number_3?.replace(/ /g, ''),
    start_date: formData.start_date ? dayjs(formData.start_date)?.format('YYYY-MM-DD') : '',
    date_of_birth: formData.date_of_birth ? dayjs(formData.date_of_birth)?.format('YYYY-MM-DD') : '',
    grad_date: formData.grad_date ? dayjs(formData.grad_date)?.format('YYYY-MM-DD'): '',
  };
};

export const ClinicianAPI = {
  createClinician: async (formData: IClinician, updateAllowedFieldsList: Array<string>) => {
    try {
      const response = await request({
        url: 'v1/clinicians',
        method: 'POST',
        data: {
          data: {
            clinician: filterObjectKeys({ ...formData,
              ...convertedFields(formData),
            }, updateAllowedFieldsList),
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },

  updateClinician: async (clinicianId: number, formData: IClinician, updateAllowedFieldsList: Array<string>) => {
    try {
      const response = await request({
        url: `v1/clinicians/${clinicianId}`,
        method: 'PUT',
        data: {
          data: {
            clinician: filterObjectKeys({ ...formData,
              ...convertedFields(formData),
            }, updateAllowedFieldsList),
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    };
  },

  getAll: async (params: IFilterParams, _?: IPublicClientApplication | undefined, signal?: AbortSignal | undefined) => {
    try {
      const response = await request({
        url: 'v1/clinicians',
        method: 'GET',
        params,
      }, { signal });
      return response.data;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  },

  getDetails: async (clinicianId: number) => {
    const response = await request({
      url: `v1/clinicians/${clinicianId}`,
      method: 'GET',
    });

    return response.data.data;
  },

  getSSN: async (clinicianId: number) => {
    const response = await request({
      url: `v1/clinicians/${clinicianId}/ssn`,
      method: 'GET',
    });

    return response.data.data;
  },

  getEntityNames: async() => {
    const response = await request({
      url: 'v1/clinicians/entity_names',
      method: 'GET',
    });

    return response.data.data;
  },
};
