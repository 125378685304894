import { useState } from 'react';

import { ClinicianContractAPI } from 'api/ClinicianContractAPI';

import StatusLabel from 'components/UIComponents/dataDisplay/StatusLabel';
import { ContractType } from 'types';
import { IClinicianContractListItem } from 'interfaces/ClinicianContract/IClinicianContractListItem';
import { IClinicianContractDetails } from 'interfaces/ClinicianContract/IClinicianContractDetails';

import useAuth from 'hooks/useAuth';
import { contractTypeOptions } from 'constants/contractsConstants';

import ContractSummarySidebar from './ContractSummarySidebar';
import VirtualTable from './VirtualTable';
import ActionsMenu from './ActionsMenu';

import { TableWithSidebarWrapper } from './styledComponents';

interface Props {
  loadMore: () => void;
  data: IClinicianContractListItem[];
  search: string;
  loading: boolean;
};

const TableWithSidebar = ({ loadMore, data, search, loading }: Props) => {
  const { user } = useAuth();

  const editAllowed = user?.allowed_write_resources.includes('clinician_contracts');

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarData, setSidebarData] = useState({});
  const [activeRowIndex, setActiveRowIndex] = useState<undefined | number>(undefined);

  const actions = (item: IClinicianContractListItem) => {
    return editAllowed ? [
      { name: 'View Contract', href: `clinician_contracts/${item.id}` },
      { name: 'Edit Contract', href: `clinician_contracts/${item.id}/edit?redirectTo=/clinician_contracts` },
      { name: 'Add Amendment', href: `clinician_contracts/${item.id}/amendments/new?redirectTo=/clinician_contracts` },
    ] : [
      { name: 'View Contract', href: `clinician_contracts/${item.id}` },
    ];
  };

  const dataFormatter = (item: IClinicianContractListItem) => ({
    id: item.id,
    entity_name: item.contract_type === 'w2' ? '' : item.entity_name,
    clinician_full_title: item.clinician_full_title,
    facility: item.facility,
    contract_type: contractTypeOptions.find(
      (option) => option.id === item.contract_type
    )?.name as ContractType,
    engagement: item.engagement,
    status: <StatusLabel status={item.status} />,
    actions: (
      <ActionsMenu data={item} actions={actions(item)} />
    ),
  });

  const setSidebarDataHandler = (index: number) => {
    ClinicianContractAPI.getDetails(index).then((response) => {
      setSidebarData(response);
      setSidebarVisible(true);
    });
  };

  return (
    <TableWithSidebarWrapper>
      <VirtualTable
        data={data.map(dataFormatter)}
        setSidebarData={setSidebarDataHandler}
        activeRowIndex={activeRowIndex}
        setActiveRowIndex={setActiveRowIndex}
        loadMore={loadMore}
        search={search}
        loading={loading}
      />
      <ContractSummarySidebar
        visible={sidebarVisible}
        sidebarData={sidebarData as IClinicianContractDetails}
        setVisible={setSidebarVisible}
        setActiveRowIndex={setActiveRowIndex}
      />
    </TableWithSidebarWrapper>
  );
};

export default TableWithSidebar;
