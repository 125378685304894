
import { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { cloneDeep } from 'lodash';
import FilterListIcon from '@mui/icons-material/FilterList';

import { TextButton, Select, IconButton, CustomIcon, DatePicker, SearchableMultiselect } from 'components/UIComponents';
import { filteringOptions as initialFilteringOptions, dateConditionOptions, comparingValueDropdownValues } from 'constants/dropdownOptions';

import { IClinicianContractFilterFormParams } from 'interfaces/ClinicianContract/IClinicianContractFilterFormParams';
import { ISelectOption } from 'interfaces/ISelectOption';

import {
  requestValuesDependingOnOption,
  dynamicFilteringOptions,
  initialFormValues,
  staticFilters,
  dateFilters,
} from 'helpers/ClinicianConracts/filters';
import { useLocalStorage } from 'hooks/useLocalStorage';

import { colors } from 'styles/globalStyles';
import {
  StyledPopover,
  PopoverContainer,
  FiltersBlock,
  ButtonsBlock,
  WhereBlock,
  StyledOutlinedButton,
  FormWrapper,
} from './styledComponents';

interface Props {
  filteringOptions: ISelectOption[],
  setFilteringOptions: Function,
  conditionOptions: Array<ISelectOption[]>,
  setConditionOptions: Function,
  valuesOptions: Array<ISelectOption[]>,
  setValuesOptions: Function,
  removeCondition: Function,
  removeItem: Function,
  pushItem: Function,
  setChipsValues: Function,
};

const Filter = ({
  filteringOptions,
  setFilteringOptions,
  conditionOptions,
  setConditionOptions,
  valuesOptions,
  setValuesOptions,
  removeCondition,
  removeItem,
  pushItem,
  setChipsValues,
}: Props) => {

  const anchorEl = useRef<HTMLButtonElement>(null);
  const [visible, setVisible] = useState(false);
  const { values, submitForm, setFieldValue } = useFormikContext<IClinicianContractFilterFormParams>();
  const [queryParams] = useLocalStorage('clinicianContractsFilters', {
    filters: [{filteringOption: 'statuses',
      condition: '=',
      value: [{id: 'Active', name: 'Active'}],
    }]}
  );

  const filteringOptionChangeHandler = async (
    setFieldValue: Function,
    index: number,
    selectedValue: string,
    previousValue: string,
    values: IClinicianContractFilterFormParams,
  ) => {
    let actualValues = cloneDeep(values);
    actualValues = {
      filters: actualValues.filters.filter(filter => filter.filteringOption !== previousValue),
    };
    const alreadySelectedOptions = [
      ...actualValues.filters.map(filter => filter.filteringOption),
      selectedValue,
    ];

    let draftFilteringOptions = cloneDeep(filteringOptions);
    setFilteringOptions(
      draftFilteringOptions.map(option => ({...option, disabled: alreadySelectedOptions.includes(option.id as string)}))
    );

    let draftOptions = cloneDeep(conditionOptions);
    if (dateFilters.includes(selectedValue)) {
      draftOptions[index] = dateConditionOptions;
    } else {
      draftOptions[index] = [{id: '=', name: 'is any of'}];
    }

    let draftValuesOptions = cloneDeep(valuesOptions);
    if (staticFilters.includes(selectedValue)) {
      draftValuesOptions[index] = comparingValueDropdownValues[selectedValue as keyof typeof comparingValueDropdownValues];
    } else {
      draftValuesOptions[index] = await requestValuesDependingOnOption(actualValues, selectedValue);
    }

    await setFieldValue(`filters[${index}].condition`, '=');
    await setFieldValue(`filters[${index}].value`, []);
    setConditionOptions(draftOptions);
    setValuesOptions(draftValuesOptions);
    setChipsValues(actualValues);
  };

  const valuesChangeHandler = async (
    submitForm: Function, values: IClinicianContractFilterFormParams, changingOptionIndex: number, setFieldValue: Function
  ) => {
    let actualValues = cloneDeep(values);
    let draftValuesOptions = cloneDeep(valuesOptions);
    const valuesToCheck = actualValues.filters.slice(changingOptionIndex+1);
    for (const value of valuesToCheck) {
      if (dynamicFilteringOptions.includes(value.filteringOption)) {
        const filteringOptionIndex = actualValues.filters.findIndex((val) => val.filteringOption === value.filteringOption);
        await setFieldValue(`filters[${filteringOptionIndex}].value`, []);
        actualValues.filters[filteringOptionIndex].value = [];
      };
    }
    for (const value of valuesToCheck) {
      if (dynamicFilteringOptions.includes(value.filteringOption)) {
        const filteringOptionIndex = actualValues.filters.findIndex((val) => val.filteringOption === value.filteringOption);
        draftValuesOptions[filteringOptionIndex] = await requestValuesDependingOnOption(actualValues, value.filteringOption);
      };
    }
    setValuesOptions(draftValuesOptions);
    submitForm();
  };

  const clearAll = (setFieldValue: Function, submitForm: Function) => {
    setFieldValue('filters', initialFormValues.filters);
    setConditionOptions([]);
    setValuesOptions([]);
    setFilteringOptions(initialFilteringOptions);
    submitForm();
  };

  useEffect(() => {
    const keys = (queryParams as IClinicianContractFilterFormParams).filters.map(filter => filter.filteringOption);
    setFilteringOptions(
      filteringOptions.map(option => ({...option, disabled: keys.includes(option.id as string)}))
    );
    let draftValuesOptions = cloneDeep(valuesOptions);
    let draftConditionOptions: Array<ISelectOption[]> = [];
    keys.forEach(async (key, index) => {
      if (staticFilters.includes(key)) {
        draftValuesOptions[index] = comparingValueDropdownValues[key as keyof typeof comparingValueDropdownValues];
      } else {
        const draftValues = {
          filters: values.filters.slice(0, index),
        };
        draftValuesOptions[index] = await requestValuesDependingOnOption(draftValues, key);
      }

      if (dateFilters.includes(key)) {
        draftConditionOptions[index] = dateConditionOptions;
      } else {
        draftConditionOptions[index] = [{id: '=', name: 'is any of'}];
      }
    });
    setValuesOptions(draftValuesOptions);
    setConditionOptions(draftConditionOptions);
  }, []);

  return (
    <>
      <StyledOutlinedButton ref={anchorEl} onClick={() => setVisible(true)}>
        <FilterListIcon fontSize='small' />
        Filters
      </StyledOutlinedButton>
      <StyledPopover
        id={'filter-popover'}
        open={true}
        anchorEl={anchorEl?.current}
        disableScrollLock
        style={{'display': visible ? 'block' : 'none'}}
        onClose={() => setVisible(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <PopoverContainer>
          <FormWrapper>
            <div>
              {values.filters.length === 0 ? 'No filter conditions are applied to this view' : 'In this view, show records' }
            </div>
            {values.filters.map((filter, index) => (
              <FiltersBlock key={index}>
                <WhereBlock>{index > 0 ? 'And' : 'Where'}</WhereBlock>
                <Select
                  name={`filters[${index}].filteringOption`}
                  allowEmpty={false}
                  label=''
                  options={filteringOptions}
                  skipPermissionsCheck
                  styles={{ width: '170px' }}
                  onChange={(value: string, previousValue: string) =>
                    filteringOptionChangeHandler(setFieldValue, index, value, previousValue, values)
                  }
                  // on blur because onchange values is previous values
                  onBlur={() => submitForm()}
                />
                <Select
                  name={`filters[${index}].condition`}
                  label=''
                  allowEmpty={false}
                  options={conditionOptions[index]}
                  onChange={() => {
                    if (filter.value !== null && filter.value.length !== 0) {
                      submitForm();
                    }
                  }}
                  skipPermissionsCheck
                  styles={{ width: '100px' }}
                />
                {dateFilters.includes(values.filters[index].filteringOption) ?
                  <DatePicker
                    name={`filters[${index}].value`}
                    label=''
                    styles={{ width: '252px' }}
                    skipPermissionsCheck
                    disableFuture={false}
                    onBlur={submitForm}
                    monthOnly={values.filters[index].condition === 'within'}
                  />
                  :
                  <SearchableMultiselect
                    label=''
                    name={`filters[${index}].value`}
                    skipPermissionsCheck
                    options={valuesOptions[index] || []}
                    allOptionAvailable={true}
                    styles={{ width: '252px' }}
                    onBlur={() => {
                      valuesChangeHandler(submitForm, values, index, setFieldValue);
                    }}
                  />
                }
                <IconButton onClick={() => removeCondition(index, removeItem, submitForm, values, setFieldValue)}>
                  <CustomIcon size='normal' color={colors.red} name='trash' />
                </IconButton>
              </FiltersBlock>
            ))}
            <ButtonsBlock>
              {values.filters.length < filteringOptions.length ?
                <TextButton
                  color='blue'
                  onClick={() => pushItem({filteringOption: '', condition: '', value: []})}
                >
                  + Add Condition
                </TextButton>
                :
                <div></div>
              }
              {values.filters.length > 0 &&
                <TextButton
                  color='red'
                  onClick={() => clearAll(setFieldValue, submitForm)}
                >
                  Clear All
                </TextButton>
              }
            </ButtonsBlock>
          </FormWrapper>
        </PopoverContainer>
      </StyledPopover>
    </>
  );
};

export default Filter;
