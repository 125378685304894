import parse from 'html-react-parser';
import { DetailsContainer, DetailsWrapper, ItalicContainer, MemoContainer, Sidebar } from 'components/base';
import { IClinician } from 'interfaces/Clinician/IClinician';
import { IFacility } from 'interfaces/Facility/IFacility';
import { contractEngagementTypeOptions, contractTypeOptions, frequencyOptions, initialMemo } from 'constants/contractsConstants';
import formatCurrency from 'helpers/formatCurrency';
import { calculateBiWeeklyRate, calculateHourlyRate, calculateWeeklyRate } from 'helpers/calculateRates';
import getActiveAmendment from 'helpers/ClinicianConracts/getActiveAmendment';
import useAuth from 'hooks/useAuth';
import { ContractType } from 'types';
import { SectionTitle, ContentWrapper } from './styledComponents';
import { IClinicianContractDetails } from 'interfaces/ClinicianContract/IClinicianContractDetails';
import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  sidebarData: IClinicianContractDetails;
  setActiveRowIndex: Function;
}

const ContractSummarySidebar = ({ visible, setVisible, sidebarData, setActiveRowIndex }: Props) => {
  const { user } = useAuth();
  const clinician: IClinician = sidebarData.clinician;
  const facility: IFacility = sidebarData.facility;
  const activeAmendment = visible ? getActiveAmendment(sidebarData) : undefined;
  const engagement = visible &&
    contractEngagementTypeOptions.find((option) => option.id === activeAmendment?.engagement)?.name;
  const contractType = visible && contractTypeOptions.find((option) => option.id === sidebarData.contract_type)?.name;

  if (!(sidebarData)) return null;

  return (
    <Sidebar
      visible={visible}
      setVisible={setVisible}
      title= {visible ?
        `${clinician.last_name} ${clinician.first_name}${clinician.maiden_name ? ` (${clinician.maiden_name}),` :','} ${clinician.title}`
        : ''}
      titleLink={`clinician_contracts/${sidebarData.id}`}
      setActiveRowIndex={setActiveRowIndex}
    >
      { visible ?
        <ContentWrapper>
          <DetailsWrapper>
            <SectionTitle>Contract Details</SectionTitle>
            <DetailsContainer name='payroll_id' label='Clinician Payroll ID' skipPermissions>{clinician.payroll_id}</DetailsContainer>
            <DetailsContainer name='entity_name' label='Entity Name' skipPermissions>
              {contractType === 'W2' ? '' : clinician.entity_name}
            </DetailsContainer>
            <DetailsContainer name='name' label='Facility' skipPermissions>{facility.name}</DetailsContainer>
            <DetailsContainer name='contract_type' label='Contract Type' skipPermissions>
              {contractType}
            </DetailsContainer>
            { sidebarData.compensation_grid ?
              <>
                <DetailsContainer name='engagement' label='Engagement' skipPermissions>
                  {`${engagement}${engagement !== 'Per-Diem' ? `, ${activeAmendment?.fte} FTE` : ''}`}
                </DetailsContainer>
                { (contractType === 'W2' && engagement !== 'Per-Diem') &&
                  <DetailsContainer name='paid_time_off' label='Paid Time Off, days' skipPermissions>
                    {activeAmendment?.paid_time_off}
                  </DetailsContainer>
                }
                { (contractType === 'PC' && engagement !== 'Per-Diem') &&
                  <DetailsContainer name='weeks_of_service' label='Weeks of Service' skipPermissions>
                    {activeAmendment?.weeks_of_service}
                  </DetailsContainer>
                }
                <DetailsContainer name='contracted_hours' label='Contracted hours' skipPermissions>
                  {activeAmendment?.contracted_hours}
                </DetailsContainer>
                <DetailsContainer name='contracted_frequency' label='Contracted Frequency' skipPermissions>
                  {frequencyOptions.find((option) => option.id === activeAmendment?.contracted_frequency)?.name}
                </DetailsContainer>
                <DetailsContainer name='payroll_frequency' label='Payroll Frequency' skipPermissions>
                  {frequencyOptions.find((option) => option.id === activeAmendment?.payroll_frequency)?.name}
                </DetailsContainer>
                {user?.role !== 'Facility Admin' && <>
                  { engagement === 'Per-Diem' ?
                    <DetailsContainer name='hourly' label='Hourly' skipPermissions>
                      {formatCurrency(activeAmendment?.hourly_rate)}
                    </DetailsContainer>
                    :
                    <>
                      <DetailsContainer name='annual_rate' label='Annually' skipPermissions>
                        {formatCurrency(activeAmendment?.annual_rate)}
                      </DetailsContainer>
                      <DetailsContainer name='bi_weekly' label='Bi-Weekly' skipPermissions>
                        {
                          formatCurrency(calculateBiWeeklyRate(
                            Number(activeAmendment?.annual_rate),
                            sidebarData.contract_type as ContractType,
                            Number(activeAmendment?.weeks_of_service)
                          ))
                        }
                      </DetailsContainer>
                      <DetailsContainer name='weekly' label='Weekly' skipPermissions>
                        {
                          formatCurrency(calculateWeeklyRate(
                            Number(activeAmendment?.annual_rate),
                            sidebarData.contract_type as ContractType,
                            Number(activeAmendment?.weeks_of_service)
                          ))
                        }
                      </DetailsContainer>
                      <DetailsContainer name='hourly' label='Hourly' skipPermissions>
                        {
                          formatCurrency(calculateHourlyRate(
                            Number(activeAmendment?.annual_rate),
                            sidebarData.contract_type as ContractType,
                            Number(activeAmendment?.weeks_of_service),
                            sidebarData.compensation_scale.contracted_hour as IContractedHour,
                            activeAmendment?.fte,
                          ))
                        }
                      </DetailsContainer>
                    </>
                  }
                  {activeAmendment?.notes &&
                    <ItalicContainer name="additional_compensations_notes" skipPermissions>
                      {activeAmendment?.notes}
                    </ItalicContainer>
                  }
                </>
                }
                <DetailsContainer name='malpractice_deduction' label='Malpractice deduction' skipPermissions>
                  {activeAmendment?.malpractice_deduction}
                </DetailsContainer>
                <DetailsContainer name='cme_pdme' label='CME/PDME' skipPermissions>
                  {sidebarData.compensation_grid?.cme_pdme}
                </DetailsContainer>
              </> : <>No compensation grid is linked to the contract</>
            }
          </DetailsWrapper>
          { sidebarData.compensation_grid &&
            <>
              {sidebarData.payroll_memo &&
                <DetailsWrapper>
                  <SectionTitle>Payroll Memo</SectionTitle>
                  <MemoContainer>
                    {sidebarData.payroll_memo === initialMemo ? '' : parse(sidebarData.payroll_memo)}
                  </MemoContainer>
                </DetailsWrapper>
              }
              <DetailsWrapper>
                <SectionTitle>Admin Memo</SectionTitle>
                <MemoContainer>
                  {sidebarData.admin_memo === initialMemo ? '' : parse(sidebarData.admin_memo)}
                </MemoContainer>
              </DetailsWrapper>
            </>
          }
        </ContentWrapper>
        :
        <></>
      }
    </Sidebar>
  );
};

export default ContractSummarySidebar;
