import { StyledMemoContainer } from './styledComponents';

interface Props {
  children: React.ReactNode;
}

const MemoContainer = ({ children }: Props) => {
  return (
    <StyledMemoContainer>
      {children}
    </StyledMemoContainer>
  );
};

export default MemoContainer;
