import { useState } from 'react';
import { InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useSearch from 'hooks/useSearch';

import { CustomIcon } from 'components/UIComponents';
import { colors } from 'styles/globalStyles';
import { StyledInputBase, StyledCircularProgress, StyledIconButton } from './styledComponents';

interface SearchFieldProps {
  disabled?: boolean,
  onChange: (value: string, searchCallback: () => void, abortController: AbortController) => void,
}

const SearchField = ({ disabled = false, onChange }: SearchFieldProps) => {
  const [value, setValue] = useState('');
  const { loading, handleSearch } = useSearch(onChange);

  const handleChange = (value: string) => {
    setValue(value);
    handleSearch(value);
  };

  const dismissSearch = () => {
    handleChange('');
  };

  const renderAdornment = () => {
    return (
      <InputAdornment position="end">
        {loading ? <StyledCircularProgress size={14} /> : ''}
        {value ? <StyledIconButton
          aria-label="Clear search"
          onClick={dismissSearch}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </StyledIconButton> : ''}
      </InputAdornment>
    );
  };

  return (
    <StyledInputBase
      data-testid='search-bar'
      disabled={disabled}
      placeholder="Search"
      inputProps={{ 'aria-label': 'search' }}
      startAdornment={<InputAdornment position="start"><CustomIcon name="search" size="normal" color={colors.textGray}/></InputAdornment>}
      endAdornment={renderAdornment()}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
    />
  );
};

export default SearchField;
