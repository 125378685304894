import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { Tooltip } from '@mui/material';

import { IClinicianContractDetails } from 'interfaces/ClinicianContract/IClinicianContractDetails';
import { IContractedHour } from 'interfaces/CompensationGrid/IContractedHour';

import { ClinicianContractAPI } from 'api/ClinicianContractAPI';
import { CustomIcon, OutlinedButton, TextButton } from 'components/UIComponents';
import { ActionsBar, PageContainer, PageHeader, SectionContainer, MemoContainer } from 'components/base';

import { contractTypeOptions, clinicianTitleOptions } from 'constants/compensationGridsConstants';
import { contractEngagementTypeOptions, initialMemo } from 'constants/contractsConstants';
import RESPONSE_CODES from 'constants/responseCodes';

import getActiveAmendment from 'helpers/ClinicianConracts/getActiveAmendment';
import useData from 'hooks/useData';
import useAuth from 'hooks/useAuth';

import AppliedCompensationGridInfo from 'containers/ClinicianContracts/ClinicianContractForm/AppliedCompensationGridInfo';
import CompensationTermsInfo from 'containers/CompensationGrids/CompensationGridDetails/CompensationTermsInfo';
import CallTermsInfo from 'containers/CompensationGrids/CompensationGridDetails/CallTermsInfo';
import StipendsInfo from 'containers/CompensationGrids/CompensationGridDetails/StipendsInfo';
import AdditionalCompensationsInfo from 'containers/CompensationGrids/CompensationGridDetails/AdditionalCompensationsInfo';
import ContractedParties from './ContractedParties';
import ContractDetails from './ContractDetails';
import PayrollDetails from './PayrollDetails';
import AmendmentsSection from './AmendmentsSection';

const ClinicianContractDetails = () => {
  const { clinicianContractId } = useParams();
  const { user } = useAuth();
  const editAllowed = user?.allowed_write_resources.includes('clinician_contracts');

  const clinicianContract: IClinicianContractDetails = useData(ClinicianContractAPI.getDetails, clinicianContractId);

  const [addAmendmentDisabledTooltip, setAddAmendmentDisabledTooltip] = useState(undefined);

  useEffect(() => {
    if (clinicianContract) {
      const action = async () => {
        const response = await ClinicianContractAPI.checkAmendmentCreateAvailable(clinicianContract.id);
        if (response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
          setAddAmendmentDisabledTooltip(response.data.error.details);
        };
      };
      action();
    }
  }, [clinicianContract]);

  if (!(clinicianContract)) return null;

  const activeAmendment = getActiveAmendment(clinicianContract);
  const facilityName = clinicianContract.facility.name;
  const clinicianTitleOption = clinicianTitleOptions
    .find(option => option.id === clinicianContract.compensation_grid?.clinician_title)?.name;
  const contractTypeOption = contractTypeOptions.find(option => option.id === clinicianContract.contract_type)?.name;
  const engagementOption = contractEngagementTypeOptions
    .find(option => option.id === clinicianContract.compensation_grid?.engagement)?.name;

  return(
    <PageContainer style={{ marginBottom: '66px' }}>
      <PageHeader pageTitle='Contract Details'>
        {editAllowed &&
          <>
            <Tooltip title={addAmendmentDisabledTooltip}>
              <div>
                <TextButton
                  color="blue"
                  disabled={addAmendmentDisabledTooltip ? true : false}
                  href={`${clinicianContractId}/amendments/new?redirectTo=/clinician_contracts/${clinicianContractId}`}
                >
                  +Add Amendment
                </TextButton>
              </div>
            </Tooltip>
            <TextButton
              color="blue"
              href={`${clinicianContractId}/edit?redirectTo=/clinician_contracts/${clinicianContractId}`}
            >
              <CustomIcon size='normal' name='edit'/>
              Edit
            </TextButton>
          </>
        }
      </PageHeader>
      <ContractedParties
        clinicianContract={clinicianContract}
      />
      <ContractDetails
        clinicianContract={clinicianContract}
        activeAmendment={activeAmendment}
      />
      { clinicianContract.compensation_grid &&
        <>
          <PayrollDetails
            clinicianContract={clinicianContract}
            activeAmendment={activeAmendment}
          />
          { clinicianContract.payroll_details.length > 1 &&
            <AmendmentsSection
              clinicianContract={clinicianContract}
              activeAmendment={activeAmendment}
            />
          }
        </>
      }
      <SectionContainer title='Compensation Grid'>
        {clinicianContract.compensation_grid ?
          <AppliedCompensationGridInfo
            compensationGridId={clinicianContract.compensation_grid?.id}
            compensationGridName={`${facilityName}, ${clinicianTitleOption}, ${contractTypeOption}, ${engagementOption}`}
            staffCatergory={clinicianContract.compensation_scale?.staff_category as string}
            selectedContractedHour={clinicianContract.compensation_scale?.contracted_hour as IContractedHour}
          />
          :
          "No compensation grid is linked to the contract. To specify the grid, hit 'Edit'"
        }
      </SectionContainer>
      { clinicianContract.compensation_grid &&
        <>
          <CompensationTermsInfo
            compensationGrid={clinicianContract.compensation_grid}
            facility={clinicianContract.facility}
          />
          <CallTermsInfo
            compensationGrid={clinicianContract.compensation_grid}
          />
          <StipendsInfo
            compensationGrid={clinicianContract.compensation_grid}
          />
          <AdditionalCompensationsInfo
            compensationGrid={clinicianContract.compensation_grid}
          />
          {clinicianContract.payroll_memo &&
            <SectionContainer title="Payroll Memo">
              <MemoContainer>
                {clinicianContract.payroll_memo === initialMemo ? '' : parse(clinicianContract.payroll_memo)}
              </MemoContainer>
            </SectionContainer>
          }
          <SectionContainer title="Admin Memo">
            <MemoContainer>
              {clinicianContract.admin_memo === initialMemo ? '' : parse(clinicianContract.admin_memo)}
            </MemoContainer>
          </SectionContainer>
        </>
      }
      <ActionsBar>
        <OutlinedButton href="/clinician_contracts">
          Back
        </OutlinedButton>
      </ActionsBar>
    </PageContainer>
  );
};

export default ClinicianContractDetails;
